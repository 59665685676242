<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p v-if="clickButton === 'default'" class="popup-content-msg">{{ $t('CARD_CHANGE_DEFAULT_COMPLETION_MESSAGE') }}</p>
            <p v-else-if="clickButton === 'delete'" class="popup-content-msg">{{ $t('CARD_DELETE_COMPLETION_MESSAGE') }}</p>
            <p v-else class="popup-content-msg">{{ $t('CARD_REGIST_COMPLETION_MESSAGE') }}</p>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="secondary" class="white--text">{{$t("CARD_REGIST_DIALOG_CANCLE_BUTTON_LABEL")}}</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import useCard from '@/stores/useCard'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const cardStore = useCard()
    return {
      loadingStore,
      cardStore
    }
  },
  components : {},
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    clickButton : {
      type    : String,
      default : null
    }
  },
  data() {
    return {
      // Errors : {}
    }
  },
  computed : {
  },
  created() {
  },
  destroyed() {
    console.log('destroyed')
  },
  methods : {
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
