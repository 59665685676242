import i18n from '@/language/i18n'
import router from '@/router'
import _ from 'lodash'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const initRegisterData = {
  cardNo       : null,
  holderName   : null,
  expireYear   : null,
  expireMonth  : null,
  securityCode : null,
  default      : true
}

const useCard = defineStore('useCard', {
  state : () => {
    return {
      locale               : 'ja',
      memberRequestNo      : null,
      clickButton          : null,
      defaultCardSeq       : null,
      cardDataList         : {},
      registerData         : _.cloneDeep(initRegisterData),
      selectCard           : {},
      registCardDialog     : false,
      comfirmCardDialog    : false,
      completionCardDialog : false,
      cardCnt              : 0,
      defaultCheckedFlag   : true,

      errors               : [],
    }
  },
  getters : {
  },
  actions : {
    reset() {
      this.editFlag = true
      this.completedFlag = false
      this.registerData = _.cloneDeep(initRegisterData)
      this.cardDataList = {}
      this.errors = []

      this.getCardList()
    },
    getCardList() {
      loadingStore.setLoading(true)
      Methods.apiExcute('private/get-card-info', this.locale)
        .then(data => {
          console.log('data:', data)
          if (data && data.cardData.length > 0) {
            this.cardDataList = data.cardData
            this.defaultCardSeq = data.default.default_card_seq
            this.cardCnt = this.cardDataList.length
            this.cardDataList = this.cardDataList.sort((item1, item2) => {
              if (String(item1.CardSeq) === String(this.defaultCardSeq)) {
                return -1
              }
              return 1
            })
          } else {
            this.cardCnt = 0
            this.registerData.default = true
          }
          loadingStore.setLoading(false)
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    defaultCheckClick(val) {
      // デフォルトカードボタンの活性・非活性
      if (val === false) {
        console.log('uncheck')
        this.registerData.default = false
      } else {
        console.log('checked')
        this.registerData.default = true
      }
    },
    checkInputFormat() {
      this.errors = []
      if (this.registerData.cardNo === null || this.registerData.cardNo.trim() === '') {
        this.errors.push(i18n.t('CARD_REGIST_CARD_NO_REQUIRED_ERROR_MESSAGE'))
      }
      if (this.registerData.holderName === null || this.registerData.holderName.trim() === '') {
        this.errors.push(i18n.t('CARD_REGIST_HOLDER_NAME_REQUIRED_ERROR_MESSAGE'))
      }
      if ((this.registerData.expireYear === null || this.registerData.expireYear === '') || (this.registerData.expireMonth === null || this.registerData.expireMonth === '')) {
        this.errors.push((i18n.t('CARD_REGIST_EXPIRE_REQUIRED_ERROR_MESSAGE')))
      } else {
        const options = {year : 'numeric', month : '2-digit', day : '2-digit'}
        const tmpDate = new Date()
        const ret = Methods.getFormatDate(tmpDate.toLocaleDateString('ja-JP', options), 'YYYY/MM')
        const expire = this.registerData.expireYear + '/' + this.registerData.expireMonth
        if (ret > expire) {
          this.errors.push((i18n.t('CARD_REGIST_EXPIRE_FORMAT_ERROR_MESSAGE')))
        }
      }
      if (this.registerData.securityCode === null || this.registerData.securityCode.trim() === '') {
        this.errors.push(i18n.t('CARD_REGIST_SECURITY_CODE_REQUIRED_ERROR_MESSAGE'))
      }
    },
    checkCard(response) {
      const code = String(response.resultCode)
      if (code === '000') {
        const token = response.tokenObject.token
        this.registCard(token)
      } else if (code === '100') {
        this.errors.push(i18n.t('CARD_REGIST_CARD_NO_REQUIRED_ERROR_MESSAGE'))
      } else if (code === '101' || code === '102') {
        this.errors.push(i18n.t('CARD_REGIST_CARD_NO_FORMAT_ERROR_MESSAGE'))
      } else if (code === '110') {
        this.errors.push(i18n.t('CARD_REGIST_EXPIRE_REQUIRED_ERROR_MESSAGE'))
      } else if (code === '111' || code === '112' || code === '113') {
        this.errors.push(i18n.t('CARD_REGIST_EXPIRE_FORMAT_ERROR_MESSAGE'))
      } else if (code === '121' || code === '122') {
        this.errors.push(i18n.t('CARD_REGIST_SECURITY_CODE_FORMAT_ERROR_MESSAGE'))
      } else {
        this.errors.push(i18n.t('CARD_REGIST_ERROR_MESSAGE'))
      }
    },
    registCard(token) {
      loadingStore.setLoading(true)
      const params = {
        token   : token,
        default : this.registerData.default,
        cardCnt : this.cardCnt
      }
      Methods.apiExcute('private/request-card', this.locale, params)
        .then(data => {
          console.log('data:', data)
          this.clickButton = 'regist'
          this.registCardDialog = false
          this.completionCardDialog = true
          this.registerData = _.cloneDeep(initRegisterData)
          this.errors = []
          loadingStore.setLoading(false)
          this.getCardList()
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    changeDefault(cardSeq) {
      this.selectCard = this.cardDataList.find(data => data.CardSeq === cardSeq)
      this.clickButton = 'default'
      this.comfirmCardDialog = true
    },
    deleteCard(cardSeq) {
      this.selectCard = this.cardDataList.find(data => data.CardSeq === cardSeq)
      this.clickButton = 'delete'
      this.comfirmCardDialog = true
    },
    updateCard() {
      loadingStore.setLoading(true)
      const params = {
        cardSeq     : this.selectCard.CardSeq,
        clickButton : this.clickButton
      }
      Methods.apiExcute('private/update-card-info', this.locale, params)
        .then(data => {
          console.log('data:', data)
          this.comfirmCardDialog = false
          this.completionCardDialog = true
          loadingStore.setLoading(false)
          this.getCardList()
        })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    closeRegistCardDialog() {
      this.registCardDialog = false
      this.registerData = _.cloneDeep(initRegisterData)
      this.errors = []
    }
  }
})

export default useCard
