<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p v-if="clickButton === 'default'" class="popup-content-msg mt-0">{{ $t('CARD_CHANGE_DIALOG_DEFAULT_MESSAGE') }}</p>
            <p v-else class="popup-content-msg mt-0">{{ $t('CARD_CHANGE_DIALOG_DELETE_MESSAGE') }}</p>
            <div class="popup-content-table-info">
              <div v-if="errors.length > 0">
                <p class="text-red">{{ errors }}</p>
              </div>
              <table>
                <tbody>
                  <tr>
                    <th>{{ $t('CARD_REGIST_DIALOG_CARD_NO_LABEL') }}</th>
                    <td v-if="clickButton === 'default'">{{selectCard.CardNo}}</td>
                    <td v-else>{{selectCard.CardNo}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="popup-content-form-btnWrap">
            <v-btn @click.stop="closeDialog" color="secondary" class="white--text select-btn">{{$t("CARD_REGIST_DIALOG_CANCLE_BUTTON_LABEL")}}</v-btn>
            <v-btn v-if="clickButton === 'default'" @click="updateCard()" color="#364A81" class="white--text select-btn">{{$t("CARD_CHANGE_UPDATE_BUTTON_LABEL")}}</v-btn>
            <v-btn v-else @click="updateCard()" color="#364A81" class="white--text select-btn">{{$t("CARD_DELET_BUTTON_LABEL")}}</v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import useCard from '@/stores/useCard'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const cardStore = useCard()
    return {
      loadingStore,
      cardStore
    }
  },
  components : {},
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    clickButton : {
      type    : String,
      default : null
    },
    selectCard : {
      type    : Object,
      default : {}
    }
  },
  data() {
    return {
    }
  },
  computed : {
    errors() {
      return this.cardStore.errors
    }
  },
  created() {
  },
  destroyed() {
    console.log('destroyed')
  },
  methods : {
    updateCard() {
      this.cardStore.updateCard()
    }
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
