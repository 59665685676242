<template>
<main id="main">
  <section id="card" class="card">
    <CardInfo/>
  </section>
</main>
</template>

<script>
import useCard from '@/stores/useCard'
import {defineComponent} from '@vue/composition-api'
import CardInfo from '../../components/CardInfo/Form'
export default defineComponent({
  setup() {
    const cardStore = useCard()
    return {
      cardStore
    }
  },
  components : {
    CardInfo
  },
  data() {
    return {
    }
  },
  computed : {
    requestFlag() {
      return this.cardStore.requestFlag
    },
    completedFlag() {
      return this.cardStore.completedFlag
    },
  },
  created() {
    this.cardStore.reset()
  },
  methods : {}
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
