<template>
<main id="main">
  <div class="wrap1160">
    <section id="card-form">
      <form>
        <p class="card-form-info" v-html="$t('CARD_INFO_FORM_ENTRY_FORM_INFO_MESSAGE')"></p>
        <p class="card-form-info text-red" v-html="$t('CARD_INFO_FORM_ENTRY_FORM_INFO_MESSAGE2')"></p>
        <div class="btn-form">
          <input type="button" class="blue" :value="$t('CARD_INFO_CREATE_BUTTON_LABEL')" @click="cardStore.registCardDialog = true; cardStore.defaultCheckedFlag=true;" :disabled="cardCnt === 5 ? true : false">
        </div>
        <div class="card-table table-scroll js-scrollable">
          <table style="height: 100%;">
            <thead>
              <tr>
                <th style="width: 35%;">{{$t("CARD_INFO_LIST_CARD_NO_FIELD")}}</th>
                <!-- <th style="width: 25%;">{{$t("CARD_INFO_LIST_CARD_BRAND_FIELD")}}</th> -->
                <th style="width: 20%;">{{$t("CARD_INFO_LIST_DEFAULT_FIELD")}}</th>
                <th style="width: 20%;">{{$t("CARD_INFO_LIST_DELETE_FIELD")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in cardDataList" :key="i">
                <td class="text-center">{{item.CardNo}}</td>
                <!-- <td class="text-center">{{item.Brand}}</td> -->
                <td v-if="item.CardSeq === defaultCardSeq" class="text-center">{{$t("CARD_INFO_LIST_DEFAULT_FIELD")}}</td>
                <td v-else><a class="btn default blue" @click="changeDefault(item.CardSeq)">{{$t("CARD_INFO_DEFAULT_BUTTON_LABEL")}}</a></td>
                <td v-if="item.CardSeq === defaultCardSeq"></td>
                <td v-else><a class="btn default error" @click="deleteCard(item.CardSeq)">{{$t("CARD_INFO_DELETE_BUTTON_LABEL")}}</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </section>
  </div>

  <RegistCardDialog
    :isOpenDialog="registCardDialog"
    :closeDialog="closeRegistCardDialog"
    :cardCnt="cardCnt"
  />
  <ComfirmCardDialog
    :isOpenDialog="comfirmCardDialog"
    :closeDialog="closeComfirmCardDialog"
    :clickButton="clickButton"
    :selectCard="selectCard"
  />
  <CompletionCardDialog
    :isOpenDialog="completionCardDialog"
    :closeDialog="closeCompletionCardDialog"
    :clickButton="clickButton"
  />
</main>
</template>

<script>
import useCard from '@/stores/useCard'
import {defineComponent} from '@vue/composition-api'
import ComfirmCardDialog from './comfirmCardDialog'
import CompletionCardDialog from './completionCardDialog'
import RegistCardDialog from './registCardDialog'

export default defineComponent({
  setup() {
    const cardStore = useCard()
    return {
      cardStore
    }
  },
  components : {
    RegistCardDialog,
    ComfirmCardDialog,
    CompletionCardDialog
  },
  props : {
  },
  data() {
    return {
    }
  },
  computed : {
    cardDataList() {
      return this.cardStore.cardDataList
    },
    defaultCardSeq() {
      return this.cardStore.defaultCardSeq
    },
    cardCnt() {
      return this.cardStore.cardCnt
    },
    clickButton() {
      return this.cardStore.clickButton
    },
    selectCard() {
      return this.cardStore.selectCard
    },
    registCardDialog() {
      return this.cardStore.registCardDialog
    },
    comfirmCardDialog() {
      return this.cardStore.comfirmCardDialog
    },
    completionCardDialog() {
      return this.cardStore.completionCardDialog
    }
  },
  methods : {
    changeDefault(cardSeq) {
      this.cardStore.changeDefault(cardSeq)
    },
    deleteCard(cardSeq) {
      this.cardStore.deleteCard(cardSeq)
    },
    closeRegistCardDialog() {
      return this.cardStore.closeRegistCardDialog()
    },
    closeComfirmCardDialog() {
      this.cardStore.comfirmCardDialog = false
    },
    closeCompletionCardDialog() {
      this.cardStore.completionCardDialog = false
    }
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
