<template>
<v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
  <v-card style="border-radius: 1rem;">
    <v-card-text class="border-bottom popup popup-fileup" style="display: block;">
      <div class="popup-wrap small">
        <a class="popup-close js-close">
          <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
        </a>
        <div class="popup-content">
          <div class="popup-content-form">
            <p class="popup-content-msg mt-0">{{ $t('CARD_REGIST_DIALOG_MESSAGE') }}</p>
            <div class="popup-content-table-info" style="margin-top: 30px;">
              <p class="text-red" v-for="text in errors" :key="text">{{ text }}</p>
              <table class="mt-0">
                <tbody>
                  <tr>
                    <th>
                      {{$t('CARD_REGIST_DIALOG_CARD_NO_LABEL')}}
                    </th>
                    <td>
                      <input type="text" class="input-text" v-model="registerData.cardNo">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{$t('CARD_REGIST_DIALOG_HOLDER_NAME_LABEL')}}
                    </th>
                    <td>
                      <input type="text" class="input-text ime-dis" v-model="registerData.holderName">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{$t('CARD_REGIST_DIALOG_EXPIRE_LABEL')}}
                    </th>
                    <td>
                      <v-row align="center">
                        <v-col class="d-flex align-center">
                          <div class="select-style">
                            <select class="year" v-model="registerData.expireYear">
                              <option :value="null"></option>
                              <option v-for="(y, index) in selectYears" :value="y" :key="index">{{y}}</option>
                            </select>
                          </div>
                          <span class="append ma-1 txt-black">/</span>
                          <div class="select-style">
                            <select class="month" v-model="registerData.expireMonth">
                              <option :value="null"></option>
                              <option v-for="(m, index) in selectMonths" :value="m" :key="index">{{m}}</option>
                            </select>
                          </div>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{$t('CARD_REGIST_DIALOG_SECURITY_CODE_LABEL')}}
                    </th>
                    <td>
                      <input type="text" class="input-text" v-model="registerData.securityCode">
                    </td>
                  </tr>
                  <tr>
                    <th>
                    </th>
                    <td v-if="cardCnt > 0">
                      <v-checkbox
                        v-model="defaultCheckedFlag"
                        @change="cardStore.defaultCheckClick"
                        :label="$t('CARD_REGIST_DIALOG_DEFAULT_LABEL')"
                      ></v-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="popup-content-form-btnWrap">
              <v-btn @click.stop="closeDialog" color="secondary" class="white--text select-btn">{{$t("CARD_REGIST_DIALOG_CANCLE_BUTTON_LABEL")}}</v-btn>
              <v-btn @click="regist" color="#364A81" class="white--text select-btn">{{$t("CARD_REGIST_DIALOG_REGIST_BUTTON_LABEL")}}</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import useCard from '@/stores/useCard'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import methods from '../../api/methods'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const cardStore = useCard()
    return {
      loadingStore,
      cardStore
    }
  },
  components : {},
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    cardCnt : {
      type    : Number,
      default : 0
    }
  },
  data() {
    return {
      // Errors : {}
    }
  },
  computed : {
    selectYears() {
      return Array.from({length : 15}, (value, index) => new Date().getFullYear() + index)
    },
    selectMonths() {
      return Array.from({length : 12}, (value, index) => String(12 - index).padStart(2, '0'))
    },
    registerData : {
      get() {
        console.log('registerData:', this.cardStore.registerData)
        return this.cardStore.registerData
      },
      set(val) {
        this.cardStore.registerData = val
      }
    },
    defaultCheckedFlag : {
      get() {
        return this.cardStore.defaultCheckedFlag
      },
      set(val) {
        this.cardStore.defaultCheckedFlag = val
      }
    },
    errors() {
      return this.cardStore.errors
    }
  },
  mounted() {
    const url = process.env.VUE_APP_CARD_PAYMENT_TOKEN_JS_URL
    methods.addExternalScriptsFromUrl([url])
  },
  destroyed() {
    console.log('destroyed')
  },
  methods : {
    regist() {
      this.cardStore.checkInputFormat()
      if (this.errors.length === 0) {
        Multipayment.init(process.env.VUE_APP_CARD_PAYMENT_SHOP_ID)
        Multipayment.getToken({
          cardno       : this.registerData.cardNo,
          expire       : this.registerData.expireYear + this.registerData.expireMonth,
          securitycode : this.registerData.securityCode,
          holdername   : this.registerData.holderName,
        }, response => this.cardStore.checkCard(response))
      }
    }
  }
})
</script>
<style lang="scss" scoped>
  .popup-content-form-wrap input {
    background: #fff !important;
  }
  .popup-content-form-wrap p.err-txt {
    font-size: 12px;
  }
  @media only screen and (max-width: 767px) {
    .popup-content-form-btnWrap {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .popup-content-table-info table tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    .popup-content-table-info table tbody tr th,
    .popup-content-table-info table tbody tr td {
      width: 100% !important;

      .input-text {
        width: 100% !important;
      }
    }
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
